import Vue from "vue";
import Router from "vue-router";

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Views
// const Welcome = () => import('@/views/pages/Welcome')
const ProductIndex = () => import("@/views/pages/product/Index");
const ProductDetails = () => import("@/views/pages/product/Details");
const ProductPromotion = () => import("@/views/pages/product/ProductPromotion");
const ProductHighlight = () => import("@/views/pages/product/ProductHighlight");
const ProductRecommend = () => import("@/views/pages/product/ProductRecommend");
const ProductSpecial = () => import("@/views/pages/product/ProductSpecial");
const ProductSpecialDetail = () =>
  import("@/views/pages/product/ProductSpecialDetail");

const QuestionIndex = () => import("@/views/pages/question/Index");
const BannerIndex = () => import("@/views/pages/banner/Index");
const BannerDetails = () => import("@/views/pages/banner/Details");
const NewsIndex = () => import("@/views/pages/news/Index");
const NewsDetails = () => import("@/views/pages/news/Details");

const PromotionList = () => import("@/views/pages/promotion/PromotionList");
const PromotionDetail = () => import("@/views/pages/promotion/PromotionDetail");
const PromotionIndex = () => import("@/views/pages/promotion/Index");
const PromotionDetails = () => import("@/views/pages/promotion/Details");

const OrderIndex = () => import("@/views/pages/order/Index");
const OrderDetails = () => import("@/views/pages/order/Details");
const OrderVerifyList = () => import("@/views/pages/order/VerifyList");
const OrderVerifyDetails = () => import("@/views/pages/order/VerifyDetails");
const AboutUs = () => import("@/views/pages/aboutus/Index");
const ContactUs = () => import("@/views/pages/contactus/Index");
const Section = () => import("@/views/pages/section/Index");
const Cookie = () => import("@/views/pages/cookie/Index");
const TermAndCondition = () => import("@/views/pages/termandcondition/Index");
const howToUse = () => import("@/views/pages/howtouse/Index");
const Privacypolicy = () => import("@/views/pages/privacypolicy/Index");
const FaqIndex = () => import("@/views/pages/faq/Index");
const FaqDetails = () => import("@/views/pages/faq/Details");
const FaqQuestionDetails = () => import("@/views/pages/faq/QuestionDetails");
const CategoryIndex = () => import("@/views/pages/category/Index");
const CategoryImgDetails = () => import("@/views/pages/category/Details");
const PartnerIndex = () => import("@/views/pages/partner/Index");
const PartnerDetails = () => import("@/views/pages/partner/Details");
const PartnerProfileDetails = () =>
  import("@/views/pages/partner/SellerDetails");
const ReviewIndex = () => import("@/views/pages/review/Index");
const ReviewDetails = () => import("@/views/pages/review/Details");
const ReviewsDetails = () => import("@/views/pages/review/ReviewDetails");
const BestDealIndex = () => import("@/views/pages/bestdeal/Index");
const OurPartnerIndex = () => import("@/views/pages/ourpartner/Index");
const WithdrawIndex = () => import("@/views/pages/withdraw/Index");
const WithdrawDetails = () => import("@/views/pages/withdraw/Details");
const SettingsIndex = () => import("@/views/pages/settings/Index");
const Cod = () => import("@/views/pages/settings/Cod");
const Tier = () => import("@/views/pages/settings/Tier");
const AdminIndex = () => import("@/views/pages/admin/Index");
const AdminDetails = () => import("@/views/pages/admin/Details");
const FinanceIndex = () => import("@/views/pages/finance/Index");
const FinanceDetails = () => import("@/views/pages/finance/Details");
const OverduePartnerIndex = () => import("@/views/pages/overduepartner/Index");
const OverduePartnerDetails = () =>
  import("@/views/pages/overduepartner/Details");
const Logo = () => import("@/views/pages/logo/Index");
const LandingPage = () => import("@/views/pages/landingpage/Index");
const CampaignIndex = () => import("@/views/pages/campaign/Index");
const CampaignDetails = () => import("@/views/pages/campaign/Details");
const CampaignVerify = () => import("@/views/pages/campaign/Verify");
const CampaignPartnerList = () => import("@/views/pages/campaign/PartnerList");
const CampaignProductList = () => import("@/views/pages/campaign/ProductList");
const ReturnIndex = () => import("@/views/pages/return/Index");
const ReturnDetails = () => import("@/views/pages/return/Details");
const RewardIndex = () => import("@/views/pages/rewards/Index");
const RewardDetails = () => import("@/views/pages/rewards/Details");
const AffiliateIndex = () => import("@/views/pages/affiliate/Index");
const AffiliateDetails = () => import("@/views/pages/affiliate/Details");
const AffiliateBankAccountDetails = () =>
  import("@/views/pages/affiliate/BankAccountDetails");
const AffiliateLog = () => import("@/views/pages/affiliate/Log");
const AffiliateFollower = () => import("@/views/pages/affiliate/Follower");
const AdsIndex = () => import("@/views/pages/ads/Index");
const AdsDetails = () => import("@/views/pages/ads/Details");
const AcademyBanner = () => import("@/views/pages/academybanner/Index");
const AcademyBannerDetails = () =>
  import("@/views/pages/academybanner/Details");
const AcademyStaticPage = () => import("@/views/pages/academy/StaticPage");
const AcademyIndex = () => import("@/views/pages/academy/Index");
const AcademyDetails = () => import("@/views/pages/academy/Details");
const AcademyTopic = () => import("@/views/pages/academytopic/Index");
const AcademyTopicDetails = () => import("@/views/pages/academytopic/Details");
const RegisterIndex = () => import("@/views/pages/register/Index");
const TermAndConPartner = () =>
  import("@/views/pages/termandcondition/Partner");
const PrivacyPolicyPartner = () =>
  import("@/views/pages/privacypolicy/Partner");
const TermAndConMember = () => import("@/views/pages/termandcondition/Member");
const PrivacyPolicyMember = () => import("@/views/pages/privacypolicy/Member");
const CategoryList = () => import("@/views/pages/category/List");
const ResendOrderIndex = () => import("@/views/pages/resendorder/Index");
const ResendOrderDetails = () => import("@/views/pages/resendorder/Details");
const Dashboard = () => import("@/views/pages/dashboard/Index");
const RevenueProduct = () => import("@/views/pages/dashboard/RevenueProduct");
const DashboardTable = () => import("@/views/pages/dashboard/TableList");
const BankIndex = () => import("@/views/pages/bank/Index");
const BankDetails = () => import("@/views/pages/bank/Details");
const MemberIndex = () => import("@/views/pages/member/Index");
const MemberDetails = () => import("@/views/pages/member/Details");
const MemberBankAccountDetails = () =>
  import("@/views/pages/member/BankAccountDetails");
const MemberLog = () => import("@/views/pages/member/Log");
const MemberWaitApproveIndex = () =>
  import("@/views/pages/memberwaitapprove/Index");
const MemberWaitApproveDetails = () =>
  import("@/views/pages/memberwaitapprove/Details");
const MemberWaitApproveBankAccountDetails = () =>
  import("@/views/pages/memberwaitapprove/BankAccountDetails");
const MemberWaitApproveLog = () =>
  import("@/views/pages/memberwaitapprove/Log");

const ProductBannerIndex = () => import("@/views/pages/product/BannerList");
const CategoryNews = () => import("@/views/pages/news/Category");
const CategoryNewsDetails = () => import("@/views/pages/news/CategoryDetail");

const CategoryTags = () => import("@/views/pages/tags/Category");
const CategoryTagsDetails = () => import("@/views/pages/tags/CategoryDetail");

const ProductBannerDetails = () =>
  import("@/views/pages/product/BannerDetails");
const PromotionBannerIndex = () => import("@/views/pages/news/BannerList");
const PromotionBannerDetails = () => import("@/views/pages/news/BannerDetails");
const LiveBannerIndex = () => import("@/views/pages/live/BannerList");
const LiveBannerDetails = () => import("@/views/pages/live/BannerDetails");

const RefundIndex = () => import("@/views/pages/refund/Index");
const RefundDetails = () => import("@/views/pages/refund/Details");

const PointIndex = () => import("@/views/pages/point/Index");
const PointExpireDetails = () => import("@/views/pages/point/expire/Details");
const CashbackIndex = () => import("@/views/pages/cashback/Index");
const CashbackExpireDetails = () =>
  import("@/views/pages/cashback/expire/Details");
const NotifyIndex = () => import("@/views/pages/notify/Index");
const ShippingPage = () => import("@/views/pages/shipping");
const ShippingPageDetail = () => import("@/views/pages/shipping/detail");
const CategoryProductGroup = () => import("@/views/pages/categoryproductgroup/Index");

const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");
const Login = () => import("@/views/pages/Login");
const ResetPassword = () => import("@/views/pages/ResetPassword");

Vue.use(Router);

export default new Router({
  mode: "history",
  linkActiveClass: "active",
  // linkExactActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: { requiresIsLogin: true },
    },
    {
      path: "/resetpassword/:code",
      name: "ResetPassword",
      component: ResetPassword,
      meta: { requiresIsLogin: true },
    },

    {
      path: "/",
      redirect: "/dashboard/productoverview",
      name: "หน้าแรก",
      component: TheContainer,
      meta: {
        requiresAuth: true,
        lv: 1,
        activeUrl: "/dashboard/productoverview",
      },
      children: [
        // {
        //   path: '/',
        //   name: 'Welcome',
        //   component: Welcome,
        //   meta: { requiresAuth: true, lv: 1},
        // },
        {
          path: "/dashboard/productoverview",
          name: "ภาพรวมสินค้า",
          component: Dashboard,
          meta: {
            requiresAuth: true,
            lv: 2,
            activeUrl: "/dashboard/productoverview",
          },
        },
        {
          path: "/dashboard/revenueproduct",
          name: "รายได้สินค้าทั้งหมด",
          component: RevenueProduct,
          meta: {
            requiresAuth: true,
            lv: 2,
            activeUrl: "/dashboard/revenueproduct",
          },
        },
        {
          path: "/dashboard/performance",
          name: "ภาพรวมของเว็บ",
          component: DashboardTable,
          meta: {
            requiresAuth: true,
            lv: 2,
            activeUrl: "/dashboard/performance",
          },
        },
        // {
        //   path: "/dashboard/campaign",
        //   name: "แฟรชเซล",
        //   component: DashboardTable,
        //   meta: {
        //     requiresAuth: true,
        //     lv: 2,
        //     activeUrl: "/dashboard/campaign"
        //   },
        // },
        {
          path: "/dashboard/productperformance",
          name: "ภาพรวมของสินค้าทั้งหมด",
          component: DashboardTable,
          meta: {
            requiresAuth: true,
            lv: 2,
            activeUrl: "/dashboard/productperformance",
          },
        },
        {
          path: "/product",
          name: "สินค้า",
          component: ProductIndex,
          meta: {
            requiresAuth: true,
            lv: 2,
            activeUrl: "/product",
          },
        },
        {
          path: "/product/details/:id",
          name: "รายละเอียดรายการคำสั่งซื้อ",
          component: ProductDetails,
          meta: {
            requiresAuth: true,
            isChildren: true,
            activeUrl: "/product",
            lv: 2,
          },
        },
        {
          path: "/product-special",
          name: "รายการราคาพิเศษ",
          component: ProductSpecial,
          meta: {
            requiresAuth: true,
            lv: 2,
            activeUrl: "/product-special",
          },
        },
        {
          path: "/product-special/details/:id",
          name: "รายละเอียดราคาพิเศษ",
          component: ProductSpecialDetail,
          meta: {
            requiresAuth: true,
            isChildren: true,
            activeUrl: "/product-special",
            lv: 2,
          },
        },
        {
          path: "promotion",
          redirect: "/promotion",
          name: "โปรโมชั่น",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: PromotionList,
              meta: {
                requiresAuth: true,
                activeUrl: "/promotion",
                lv: 2,
              },
            },
            {
              path: "details/:id",
              name: "รายละเอียดโปรโมชั่น",
              component: PromotionDetail,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/promotion",
                lv: 2,
              },
            },
          ],
        },
        // {
        //   path: 'promotion',
        //   redirect: '/promotion',
        //   name: 'โปรโมชั่น',
        //   component: {
        //     render (c) { return c('router-view') }
        //   },
        //   children: [
        //     {
        //       path: '',
        //       name: '',
        //       component: PromotionIndex,
        //       meta: {
        //         requiresAuth: true,
        //         activeUrl: "/promotion",
        //         lv: 2
        //        }
        //     },
        //     {
        //       path: 'details/:id',
        //       name: 'รายละเอียดโปรโมชั่น',
        //       component: PromotionDetails,
        //       meta: {
        //         requiresAuth: true,
        //         isChildren: true,
        //         activeUrl: "/promotion",
        //         lv: 2
        //       },
        //     },
        //   ]
        // },
        {
          path: "/question",
          name: "Question",
          component: QuestionIndex,
          meta: {
            requiresAuth: true,
            lv: 2,
            activeUrl: "/question",
          },
        },
        {
          path: "banner",
          redirect: "/banner",
          name: "หน้าหลัก - แบนเนอร์",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: BannerIndex,
              meta: {
                requiresAuth: true,
                activeUrl: "/banner",
                lv: 2,
              },
            },
            {
              path: "details/:id",
              name: "รายละเอียดแบนเนอร์",
              component: BannerDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/banner",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "news",
          redirect: "/news",
          name: "บทความ",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: NewsIndex,
              meta: {
                requiresAuth: true,
                activeUrl: "/news",
                lv: 2,
              },
            },
            {
              path: "details/:id",
              name: "รายละเอียดข่าวสาร",
              component: NewsDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/news",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "order",
          redirect: "/order",
          name: "คำสั่งซื้อ",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: OrderIndex,
              meta: {
                requiresAuth: true,
                activeUrl: "/order",
                lv: 2,
              },
            },
            {
              path: "details/:id",
              name: "รายละเอียดรายการคำสั่งซื้อ",
              component: OrderDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/order",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "resendorder",
          redirect: "/resendorder",
          name: "รีเซนออเดอร์",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: ResendOrderIndex,
              meta: {
                requiresAuth: true,
                activeUrl: "/resendorder",
                lv: 2,
              },
            },
            {
              path: "details/:id",
              name: "รายละเอียดรายการรีเซนออเดอร์",
              component: ResendOrderDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/resendorder",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "order/verify",
          redirect: "/order/verify",
          name: "รอยืนยันการชำระเงิน",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: OrderVerifyList,
              meta: {
                requiresAuth: true,
                activeUrl: "/order/verify",
                lv: 2,
              },
            },
            {
              path: "details/:id",
              name: "รายละเอียดรายการรอการตรวจสอบ",
              component: OrderVerifyDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/order/verify",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "/our-story",
          name: "เรื่องราวของเรา",
          component: AboutUs,
          meta: {
            requiresAuth: true,
            lv: 2,
            activeUrl: "/our-story",
          },
        },
        {
          path: "/cookie-policy",
          name: "นโยบายคุกกี้",
          component: Cookie,
          meta: {
            requiresAuth: true,
            lv: 2,
            activeUrl: "/cookie-policy",
          },
        },
        {
          path: "/product-promotion",
          name: "หน้าหลัก - สินค้าโปรโมชั่น",
          component: ProductPromotion,
          meta: {
            requiresAuth: true,
            lv: 2,
            activeUrl: "/product-promotion",
          },
        },
        {
          path: "/product-recommend",
          name: "หน้าหลัก - สินค้าแนะนำ",
          component: ProductRecommend,
          meta: {
            requiresAuth: true,
            lv: 2,
            activeUrl: "/product-recommend",
          },
        },
        {
          path: "/product-highlight",
          name: "หน้าหลัก - สินค้าไฮไลท์",
          component: ProductHighlight,
          meta: {
            requiresAuth: true,
            lv: 2,
            activeUrl: "/product-highlight",
          },
        },
        {
          path: "/section",
          name: "จัดการเว็บไซต์",
          component: Section,
          meta: {
            requiresAuth: true,
            lv: 2,
            activeUrl: "/section",
          },
        },
        {
          path: "/contactus",
          name: "ติดต่อเรา",
          component: ContactUs,
          meta: {
            requiresAuth: true,
            lv: 2,
            activeUrl: "/contactus",
          },
        },
        {
          path: "/term-and-condition",
          name: "ข้อตกลงและเงื่อนไขการใช้งาน",
          component: TermAndCondition,
          meta: {
            requiresAuth: true,
            lv: 2,
            activeUrl: "/term-and-condition",
          },
        },
        {
          path: "/how-to-use",
          name: "เงื่อนไขการใช้งาน",
          component: howToUse,
          meta: {
            requiresAuth: true,
            lv: 2,
            activeUrl: "/how-to-use",
          },
        },
        {
          path: "/privacy-policy",
          name: "นโยบายความเป็นส่วนตัว",
          component: Privacypolicy,
          meta: {
            requiresAuth: true,
            lv: 2,
            activeUrl: "/privacy-policy",
          },
        },
        {
          path: "faq",
          redirect: "/faq",
          name: "คำถามที่พบบ่อย",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: FaqIndex,
              meta: {
                requiresAuth: true,
                activeUrl: "/faq",
                lv: 2,
              },
            },
            {
              path: "details/:id",
              name: "จัดการคำถามที่พบบ่อย",
              component: FaqDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/faq",
                lv: 2,
              },
            },
            {
              path: "details/question/:id",
              name: "จัดการคำถามที่พบบ่อย",
              component: FaqQuestionDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/faq",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "faq/partner",
          redirect: "/faq/partner",
          name: "คำถามที่พบบ่อย (ลูกค้าบริษัท)",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: FaqIndex,
              meta: {
                requiresAuth: true,
                activeUrl: "/faq/partner",
                lv: 3,
              },
            },
            {
              path: "details/:id",
              name: "จัดการคำถามที่พบบ่อย",
              component: FaqDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/faq/partner",
                lv: 3,
              },
            },
            {
              path: "details/question/:id",
              name: "จัดการคำถามที่พบบ่อย",
              component: FaqQuestionDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/faq/partner",
                lv: 3,
              },
            },
          ],
        },
        {
          path: "faq/member",
          redirect: "/faq/member",
          name: "คำถามที่พบบ่อย (ลูกค้าทั่วไป)",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: FaqIndex,
              meta: {
                requiresAuth: true,
                activeUrl: "/faq/member",
                lv: 3,
              },
            },
            {
              path: "details/:id",
              name: "จัดการคำถามที่พบบ่อย",
              component: FaqDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/faq/member",
                lv: 3,
              },
            },
            {
              path: "details/question/:id",
              name: "จัดการคำถามที่พบบ่อย",
              component: FaqQuestionDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/faq/member",
                lv: 3,
              },
            },
          ],
        },
        {
          path: "/category",
          name: "หมวดหมู่",
          component: CategoryIndex,
          meta: {
            requiresAuth: true,
            activeUrl: "/category",
            lv: 2,
          },
        },
        {
          path: "category/imagelist",
          redirect: "/category/imagelist",
          name: "จัดการรูปหมวดหมู่",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: CategoryList,
              meta: {
                requiresAuth: true,
                activeUrl: "/category/imagelist",
                lv: 2,
              },
            },
            {
              path: "details/:id",
              name: "รายละเอียดรูปหมวดหมู่",
              component: CategoryImgDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/category/imagelist",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "partner",
          redirect: "/partner",
          name: "รายการแบรนด์",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: PartnerIndex,
              meta: {
                requiresAuth: true,
                activeUrl: "/partner",
                lv: 1,
              },
            },
            {
              path: "details/:id",
              name: "รายละเอียดแบรนด์",
              component: PartnerDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/partner",
                lv: 1,
              },
            },
            {
              path: "details/profile/:id",
              name: "รายละเอียดแบรนด์",
              component: PartnerProfileDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/partner",
                lv: 1,
              },
            },
          ],
        },
        {
          path: "review",
          redirect: "/review",
          name: "รายการรีวิว",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: ReviewIndex,
              meta: {
                requiresAuth: true,
                activeUrl: "/review",
                lv: 2,
              },
            },
            {
              path: "details/:id",
              name: "รายละเอียดรีวิว",
              component: ReviewDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/review",
                lv: 2,
              },
            },
            {
              path: "details/reviews/:id",
              name: "รายละเอียดรายการรีวิว",
              component: ReviewsDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/review",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "/bestdeal",
          name: "รายการดีล",
          component: BestDealIndex,
          meta: {
            requiresAuth: true,
            lv: 2,
            activeUrl: "/bestdeal",
          },
        },
        {
          path: "/our-partner",
          name: "รายการแบรนด์ของเรา",
          component: OurPartnerIndex,
          meta: {
            requiresAuth: true,
            lv: 2,
            activeUrl: "/our-partner",
          },
        },
        {
          path: "withdraw",
          redirect: "/withdraw",
          name: "คำขอถอนเงินตัวแทน",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: WithdrawIndex,
              meta: {
                requiresAuth: true,
                activeUrl: "/withdraw",
                lv: 2,
              },
            },
            {
              path: "details/:id",
              name: "รายละเอียดคำขอถอนเงินตัวแทน",
              component: WithdrawDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/withdraw",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "/settings",
          name: "ตั้งค่าระบบ",
          component: SettingsIndex,
          meta: {
            requiresAuth: true,
            lv: 2,
            activeUrl: "/settings",
          },
        },
        {
          path: "/settings/cod",
          name: "ตั้งค่าระบบการจัดส่ง",
          component: Cod,
          meta: {
            requiresAuth: true,
            lv: 2,
            activeUrl: "/settings/cod",
          },
        },
        {
          path: "/settings/tier",
          name: "ระบบปรับเปลี่ยนระดับผู้ใช้",
          component: Tier,
          meta: {
            requiresAuth: true,
            lv: 2,
            activeUrl: "/settings/tier",
          },
        },
        {
          path: "admin",
          redirect: "/admin",
          name: "รายการผู้ดูแลระบบ",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: AdminIndex,
              meta: {
                requiresAuth: true,
                activeUrl: "/admin",
                lv: 2,
              },
            },
            {
              path: "details/:id",
              name: "รายละเอียดผู้ดูแลระบบ",
              component: AdminDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/admin",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "finance",
          redirect: "/finance",
          name: "รายการการเงินแบรนด์",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: FinanceIndex,
              meta: {
                requiresAuth: true,
                activeUrl: "/finance",
                lv: 2,
              },
            },
            {
              path: "details/:id",
              name: "รายละเอียดรายการการเงินแบรนด์",
              component: FinanceDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/finance",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "overdue-partner",
          redirect: "/overdue-partner",
          name: "รายการค้างชำระแบรนด์",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: OverduePartnerIndex,
              meta: {
                requiresAuth: true,
                activeUrl: "/overdue-partner",
                lv: 2,
              },
            },
            {
              path: "details/:id",
              name: "รายละเอียดรายการค้างชำระแบรนด์",
              component: OverduePartnerDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/overdue-partner",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "/logo",
          name: "โลโก้",
          component: Logo,
          meta: {
            requiresAuth: true,
            lv: 2,
            activeUrl: "/logo",
          },
        },
        {
          path: "/landingpage",
          name: "จัดการภาพ Landing page",
          component: LandingPage,
          meta: {
            requiresAuth: true,
            lv: 2,
            activeUrl: "/landingpage",
          },
        },
        {
          path: "campaign",
          redirect: "/campaign",
          name: "รายการแฟรชเซล",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: CampaignIndex,
              meta: {
                requiresAuth: true,
                activeUrl: "/campaign",
                lv: 2,
              },
            },
            {
              path: "details/:id",
              name: "รายละเอียดแฟรชเซล",
              component: CampaignDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/campaign",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "campaign/verify",
          redirect: "/campaign/verify",
          name: "รายการรอการตรวจสอบเพื่อเข้าแฟรชเซล",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: CampaignVerify,
              meta: {
                requiresAuth: true,
                activeUrl: "/campaign/verify",
                lv: 2,
              },
            },
            {
              path: "partnerlist/:id",
              name: "รายชื่อแบรนด์",
              component: CampaignPartnerList,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/campaign/verify",
                lv: 2,
              },
            },
            {
              path: "productlist/:campid/:sellerid",
              name: "รายการสินค้าของแบรนด์",
              component: CampaignProductList,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/campaign/verify",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "return",
          redirect: "/return",
          name: "รายการคืนสินค้า",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: ReturnIndex,
              meta: {
                requiresAuth: true,
                activeUrl: "/return",
                lv: 2,
              },
            },
            {
              path: "details/:id",
              name: "รายละเอียดการคืนสินค้า",
              component: ReturnDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/return",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "voucher",
          redirect: "/voucher",
          name: "รายการส่วนลด",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: RewardIndex,
              meta: {
                requiresAuth: true,
                activeUrl: "/voucher",
                lv: 2,
              },
            },
            {
              path: "details/:id",
              name: "รายละเอียดวอชเชอร์",
              component: RewardDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/voucher",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "affiliate",
          redirect: "/affiliate",
          name: "รายการลูกค้าบริษัท",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: AffiliateIndex,
              meta: {
                requiresAuth: true,
                activeUrl: "/affiliate",
                lv: 1,
              },
            },
            {
              path: "details/:id",
              name: "รายละเอียดลูกค้าบริษัท",
              component: AffiliateDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/affiliate",
                lv: 1,
              },
            },
            {
              path: "bankaccount/details/:id",
              name: "รายละเอียดบัญชีตัวแทน",
              component: AffiliateBankAccountDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/affiliate",
                lv: 1,
              },
            },
            {
              path: "log/:id",
              name: "ประวัติ",
              component: AffiliateLog,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/affiliate",
                lv: 1,
              },
            },
            {
              path: "follower/:id",
              name: "รายการผู้ติดตาม",
              component: AffiliateFollower,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/affiliate",
                lv: 1,
              },
            },
          ],
        },
        {
          path: "ads",
          redirect: "/ads",
          name: "รายการโฆษณา",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: AdsIndex,
              meta: {
                requiresAuth: true,
                activeUrl: "/ads",
                lv: 2,
              },
            },
            {
              path: "details/:id",
              name: "รายละเอียดโฆษณา",
              component: AdsDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/ads",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "academy-banner",
          redirect: "/academy-banner",
          name: "รายการแบนเนอร์อะคาเดมี่",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: AcademyBanner,
              meta: {
                requiresAuth: true,
                activeUrl: "/academy-banner",
                lv: 3,
              },
            },
            {
              path: "details/:id",
              name: "รายละเอียดแบนเนอร์อะคาเดมี่",
              component: AcademyBannerDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/academy-banner",
                lv: 3,
              },
            },
          ],
        },
        {
          path: "academy",
          redirect: "/academy",
          name: "รายการอะคาเดมี่",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: AcademyIndex,
              meta: {
                requiresAuth: true,
                activeUrl: "/academy",
                lv: 3,
              },
            },
            {
              path: "details/:id",
              name: "ข้อมูลอะคาเดมี่",
              component: AcademyDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/academy",
                lv: 3,
              },
            },
            {
              path: "academy-01",
              name: "จัดการอะคาเดมี่ 1",
              component: AcademyStaticPage,
              meta: {
                requiresAuth: true,
                lv: 3,
                activeUrl: "/academy/academy-01",
              },
            },
            {
              path: "academy-02",
              name: "จัดกาอะคาเดมี่ 2",
              component: AcademyStaticPage,
              meta: {
                requiresAuth: true,
                lv: 3,
                activeUrl: "/academy/academy-02",
              },
            },
            {
              path: "academy-03",
              name: "จัดการอะคาเดมี่ 3",
              component: AcademyStaticPage,
              meta: {
                requiresAuth: true,
                lv: 3,
                activeUrl: "/academy/academy-03",
              },
            },
            {
              path: "academy-04",
              name: "จัดการอะคาเดมี่ 4",
              component: AcademyStaticPage,
              meta: {
                requiresAuth: true,
                lv: 3,
                activeUrl: "/academy/academy-04",
              },
            },
          ],
        },
        {
          path: "academy-topic",
          redirect: "/academy-topic",
          name: "รายการหัวข้ออะคาเดมี่",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: AcademyTopic,
              meta: {
                requiresAuth: true,
                activeUrl: "/academy-topic",
                lv: 3,
              },
            },
            {
              path: "details/:id",
              name: "ข้อมูลหัวข้ออะคาเดมี่",
              component: AcademyTopicDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/academy-topic",
                lv: 3,
              },
            },
          ],
        },
        {
          path: "/register",
          name: "วิธีการสมัคร",
          component: RegisterIndex,
          meta: {
            requiresAuth: true,
            lv: 3,
            activeUrl: "/register",
          },
        },
        {
          path: "/term-and-condition-partner",
          name: "ข้อกำหนดและเงื่อนไข (ลูกค้าบริษัท)",
          component: TermAndConPartner,
          meta: {
            requiresAuth: true,
            lv: 3,
            activeUrl: "/term-and-condition-partner",
          },
        },
        {
          path: "/privacy-policy-partner",
          name: "นโยบายข้อมูลส่วนบุคคล (ลูกค้าบริษัท)",
          component: PrivacyPolicyPartner,
          meta: {
            requiresAuth: true,
            lv: 3,
            activeUrl: "/privacy-policy-partner",
          },
        },
        {
          path: "/term-and-condition-member",
          name: "ข้อกำหนดและเงื่อนไข (ลูกค้าทั่วไป)",
          component: TermAndConMember,
          meta: {
            requiresAuth: true,
            lv: 3,
            activeUrl: "/term-and-condition-member",
          },
        },
        {
          path: "/privacy-policy-member",
          name: "นโยบายข้อมูลส่วนบุคคล (ลูกค้าทั่วไป)",
          component: PrivacyPolicyMember,
          meta: {
            requiresAuth: true,
            lv: 3,
            activeUrl: "/privacy-policy-member",
          },
        },
        {
          path: "bank",
          redirect: "/bank",
          name: "รายการบัญชีรับเงินระบบ",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: BankIndex,
              meta: {
                requiresAuth: true,
                activeUrl: "/bank",
                lv: 2,
              },
            },
            {
              path: "details/:id",
              name: "รายละเอียดรายการบัญชีรับเงินระบบ",
              component: BankDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/bank",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "member",
          redirect: "/member",
          name: "รายการลูกค้าทั่วไป",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: MemberIndex,
              meta: {
                requiresAuth: true,
                activeUrl: "/member",
                lv: 1,
              },
            },
            {
              path: "details/:id",
              name: "รายละเอียดเมมเบอร์",
              component: MemberDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/member",
                lv: 1,
              },
            },
            {
              path: "bankaccount/details/:id",
              name: "รายละเอียดบัญชีเมมเบอร์",
              component: MemberBankAccountDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/member",
                lv: 1,
              },
            },
            {
              path: "log/:id",
              name: "ประวัติ",
              component: MemberLog,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/member",
                lv: 1,
              },
            },
          ],
        },
        {
          path: "member-wait-approve",
          redirect: "/member-wait-approve",
          name: "รายการอัพเกรดรอยืนยัน",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: MemberWaitApproveIndex,
              meta: {
                requiresAuth: true,
                activeUrl: "/member-wait-approve",
                lv: 1,
              },
            },
            {
              path: "details/:id",
              name: "รายละเอียดเมมเบอร์",
              component: MemberWaitApproveDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/member-wait-approve",
                lv: 1,
              },
            },
            {
              path: "bankaccount/details/:id",
              name: "รายละเอียดบัญชีเมมเบอร์",
              component: MemberWaitApproveBankAccountDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/member-wait-approve",
                lv: 1,
              },
            },
            {
              path: "log/:id",
              name: "ประวัติ",
              component: MemberWaitApproveLog,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/member-wait-approve",
                lv: 1,
              },
            },
          ],
        },
        {
          path: "product/banner",
          redirect: "/product/banner",
          name: "แบนเนอร์สินค้า",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: ProductBannerIndex,
              meta: {
                requiresAuth: true,
                activeUrl: "/product/banner",
                lv: 2,
              },
            },
            {
              path: "details/:id",
              name: "รายละเอียดแบนเนอร์สินค้า",
              component: ProductBannerDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/product/banner",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "category/news",
          redirect: "/category/news",
          name: "หมวดหมู่บทความ",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: CategoryNews,
              meta: {
                requiresAuth: true,
                activeUrl: "/category/news",
                lv: 2,
              },
            },
            {
              path: "details/:id",
              name: "รายละเอียดหมวดหมู่บทความ",
              component: CategoryNewsDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/category/news",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "category/tags",
          redirect: "/category/tags",
          name: "หมวดหมู่ป้ายสินค้า",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: CategoryTags,
              meta: {
                requiresAuth: true,
                activeUrl: "/category/tags",
                lv: 2,
              },
            },
            {
              path: "details/:id",
              name: "รายละเอียดหมวดหมู่ป้ายสินค้า",
              component: CategoryTagsDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/category/tags",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "news/banner",
          redirect: "/news/banner",
          name: "แบนเนอร์บทความ",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: PromotionBannerIndex,
              meta: {
                requiresAuth: true,
                activeUrl: "/news/banner",
                lv: 2,
              },
            },
            {
              path: "details/:id",
              name: "รายละเอียดแบนเนอร์บทความ",
              component: PromotionBannerDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/news/banner",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "category/productgroup",
          redirect: "/category/productgroup",
          name: "หมวดหมู่สินค้ากลุ่ม",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: CategoryProductGroup,
              meta: {
                requiresAuth: true,
                activeUrl: "/category/productgroup",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "live/banner",
          redirect: "/live/banner",
          name: "แบนเนอร์ Live",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: LiveBannerIndex,
              meta: {
                requiresAuth: true,
                activeUrl: "/live/banner",
                lv: 2,
              },
            },
            {
              path: "details/:id",
              name: "รายละเอียดแบนเนอร์ Live",
              component: LiveBannerDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/live/banner",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "refund",
          redirect: "/refund",
          name: "รายการที่ต้องโอนคืน",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: RefundIndex,
              meta: {
                requiresAuth: true,
                activeUrl: "/refund",
                lv: 2,
              },
            },
            {
              path: "details/:id",
              name: "รายละเอียดรายการที่ต้องโอนคืน",
              component: RefundDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/refund",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "point",
          redirect: "/point",
          name: "รายการคะแนนในระบบ",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: PointIndex,
              meta: {
                requiresAuth: true,
                activeUrl: "/point",
                lv: 2,
              },
            },
            {
              path: "expire/details/:date",
              name: "รายละเอียดรายการคะแนนที่หมดอายุ",
              component: PointExpireDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/point",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "cashback",
          redirect: "/cashback",
          name: "รายการเงินคืนในระบบ",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: CashbackIndex,
              meta: {
                requiresAuth: true,
                activeUrl: "/cashback",
                lv: 2,
              },
            },
            {
              path: "expire/details/:date",
              name: "รายละเอียดรายการเงินคืนที่หมดอายุ",
              component: CashbackExpireDetails,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/cashback",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "/notify-improvements",
          name: "ประชาสัมพันธ์",
          component: NotifyIndex,
          meta: {
            requiresAuth: true,
            lv: 3,
            activeUrl: "/notify-improvements",
          },
        },
        {
          path: "/shipping-setting",
          name: "ตั้งค่าการจัดส่ง",
          component: ShippingPage,
          meta: {
            requiresAuth: true,
            lv: 1,
            activeUrl: "/shipping-setting",
          },
        },
        {
          path: "shipping-setting",
          redirect: "/shipping-setting",
          name: "ตั้งค่าการจัดส่ง",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: ShippingPage,
              meta: {
                requiresAuth: true,
                activeUrl: "/shipping-setting",
                lv: 2,
              },
            },
            {
              path: "details/:id",
              name: "รายละเอียดรายการบัญชีรับเงินระบบ",
              component: ShippingPageDetail,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/shipping-setting",
                lv: 2,
              },
            },
          ],
        },
        // {
        //   path: '/',
        //   redirect: '/',
        //   name: 'Theme',
        //   component: {
        //     render(c) { return c('router-view') }
        //   },
        //   children: [
        //     {
        //       path: '/',
        //       name: 'Home',
        //       component: Home
        //     },
        //   ]
        // },
        //{ path: '*', redirect: '/'},
        {
          path: "404",
          name: "Page404",
          component: Page404,
        },
        {
          path: "500",
          name: "Page500",
          component: Page500,
        },
      ],
    },
    {
      path: "*",
      name: "Page404",
      component: Page404,
    },
  ],
});
